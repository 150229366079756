







































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: this.$t("TRUCK_ID"), value: "id" },
        { text: this.$t("LICENSE_PLATE"), value: "license_plate" },
        { text: "Loại xe", value: "type" },
        { text: "Công ty", value: "company" },
        { text: this.$t("STATUS"), value: "status" },
        { text: this.$t("TOTAL_WEIGHT"), value: "total_weight" },
        { text: this.$t("TOTAL_VOLUME"), value: "total_volume" },
        { text: this.$t("TRUCK_CREATED_TIME"), value: "created_at" },
        { text: this.$t("LOAD_TIME"), value: "load_time" },
        { text: this.$t("GATE_TIME"), value: "gate_time" },
        { text: this.$t("VN_TIME"), value: "vn_time" },
        { text: "Check thuế", value: "total_tax_checked" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
        },
      ],
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {
        status: "",
      },
      defaultItem: {
        status: "",
      },
      availableStatusMap: {
        dk: this.$t("STATUS_DK"),
        x: this.$t("STATUS_X"),
        ck: this.$t("STATUS_CK"),
        vn: this.$t("STATUS_VN"),
      },
      availableTypeMap: {
        tap_13m: "Tạp 13m",
        tap_9m6: "Tạp 9m6",
        tap_17m: "Tạp 17m",
        tap_cont: "Cont tạp",
        tap_cont_20: "Cont tạp 20",
        tap_cont_40: "Cont tạp 40",
        tx: "Tách xe",
        nx: "Nguyên xe",
        nx_13m: "Nguyên xe 13m",
        nx_17m: "Nguyên xe 17m",
        nx_air: "Air",
        nx_cb: "Nguyên cont",
        nx_l: "Nguyên cont lẻ",
        db_cont_20: "Cont 20 đặc biệt",
        db_cont_40: "Cont 40 đặc biệt",
      },
      availableCompanyMap: {
        dd: "Đại Dương",
        hb: "Hoàng Bảo",
      },
      filterStatus: "",
    };
  },
  computed: {
    formTitle() {
      return this.$t("EDIT");
    },
    availableStatuses() {
      const items = [];
      for (const k in this.availableStatusMap) {
        if (k === "dk" && !userManager.checkRole(["xnk", "cs"])) {
          continue;
        }
        items.push({
          text: this.availableStatusMap[k],
          value: k,
        });
      }
      return items;
    },
    availableTypes() {
      const items = [];
      for (const k in this.availableTypeMap) {
        if (k === "nx") {
          continue;
        }
        items.push({
          text: this.availableTypeMap[k],
          value: k,
        });
      }
      return items;
    },
    availableCompanies() {
      const items = [];
      for (const k in this.availableCompanyMap) {
        if (k === "nx") {
          continue;
        }
        items.push({
          text: this.availableCompanyMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    filterStatus() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      const filters = [];
      if (this.filterStatus) {
        filters.push({
          key: "status",
          operator: "=",
          value: this.filterStatus,
        });
      }
      this.loading = true;
      const { items, count } = await apiClient.truckGetAll({
        options: this.options,
        filters,
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.truckDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const result = await apiClient.truckUpdate(this.editedItem, ["status", "license_plate", "type", "company"]);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    async exportItem(item) {
      location.href = apiClient.truckExport(item);
    },
    async exportAdvancedItem(item) {
      location.href = apiClient.truckExportAdvanced(item);
    },
  },
});
